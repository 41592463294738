import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Image, Carousel, Button, NavbarCollapse } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/productDetailCore.css';
import Idea from '../../Components/Customer/Idea';
import Cart from '../../Components/Customer/cart';
import Related from '../../Components/Customer/related';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import heart_img from '../../assets/images/Heart_After.svg';
const ProductDetailCore = () => {
    const location = useLocation(); // Provides the current URL object
    const params = new URLSearchParams(location.search);
    const querystring = params.get("ProductId"); // Get the value for param1
    const [ProductId, setProductId] = useState('');
    const [CartId, SetCartId] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]); // Initial state
    const [isOpen, setIsOpen] = useState(false);
    const [isOpens, setIsOpens] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0); // Track changes to trigger re-render
    $(".dvCart").hide();
    // Function to handle button click and force a re-render
    
    useEffect(() => {
        HighlightSize();
        fetchProductDetails();
        

    }, [ProductId])
    function Highlight(event) {

        const size = event.target.id;
        $(".circleLarge").removeClass("circleLargeAfter");
        $("." + size).addClass("circleLargeAfter");
    }
    const HighlightSize = async () => {
        $(".S").addClass('circleLargeAfter');
    }
    const togglePanel = () => {
        setIsOpen(!isOpen);
    };
    const togglePanelreturn = () => {
        setIsOpens(!isOpens);
    };
    async function Addcart() {

        const formData = new FormData();
        let size = $(".circleLargeAfter").val();
        let CartId = sessionStorage.getItem('sessioncart_Id');

        formData.append('product_ID', ProductId);
        formData.append('size', size);
        formData.append('quantity', 1);
        debugger;
        if (CartId != null) {
            formData.append('cart_ID',CartId);
            const response = await axios.post('https://api.rollerfashions.com/api/Cart/Add_cart', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            var getresponse = response.data;
        sessionStorage.setItem('sessioncart_Id', getresponse.responseobject);
        SetCartId(getresponse.responseobject);
        }
        else {
            const response = await axios.post('https://api.rollerfashions.com/api/Cart/Add_cart', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            var getresponse = response.data;
        sessionStorage.setItem('sessioncart_Id', getresponse.responseobject);
        SetCartId(getresponse.responseobject);
        }
        
        setRefreshKey(prevKey => prevKey + 1);
        $(".dvCart").fadeIn();
    }
    const fetchProductDetails = async () => {
        setProductId(querystring);

        if (ProductId != '') {
            let apiUrl = `https://api.rollerfashions.com/api/Home/Product_Description?Product_ID=${ProductId}`;

            fetch(apiUrl) // Replace with your API endpoint
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    setData(data);
                    setLoading(false);
                    sessionStorage.setItem('sessionKey', data.category_ID);

                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
        if (loading) return <div> Loading...</div>;
        if (error) return <div>Error: {error.message}</div>;

    };

    return (
        <>
            <div className='padding margintop'>
                <Container fluid>
                    <div className='pructdetail'>
                        <Row>
                            <Col md={6}>

                                <Carousel fade>
                                    <Carousel.Item>
                                        <img src={data.display_image} className="d-block w-100" alt={data.name} />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={data.sample_image1} className="d-block w-100" alt={data.name} />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={data.sample_image2} className="d-block w-100" alt={data.name} />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={data.sample_image3} className="d-block w-100" alt={data.name} />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={data.sample_image4} className="d-block w-100" alt={data.name} />
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                            <Col md={6}>
                                <div className='padding margintop PrductDetail'>
                                    <label>Roller Fashions</label>
                                    <h3>
                                        {data.name}
                                    </h3>
                                    <hr>
                                    </hr>
                                    <h3> &#x20b9; <strike className="mrpstrike">{data.price}</strike> {data.selling_price} /-</h3>
                                    <label>M.R.P Incl. all taxes</label>
                                    <br></br>
                                    <label className='mt-4'><b>Select Size:</b></label> <a href="#" className='aschrt'> VIEW SIZE CHART</a><br></br>
                                    <div className='mt-2 margin0'>
                                        {data.s && data.s.length > 0} {
                                            <Button id="S" onClick={Highlight} value="S" className='circleLarge S'>S</Button>
                                        }
                                        {data.m && data.m.length > 0}{
                                            <Button id="M" onClick={Highlight} value="M" className='circleLarge M'>M</Button>}
                                        {data.l && data.l.length > 0}{
                                            <Button id="L" onClick={Highlight} value="L" className='circleLarge L'>L</Button>}
                                        {data.xl && data.xl.length > 0}{
                                            <Button id="XL" onClick={Highlight} value="XL" className='circleLarge XL'>XL</Button>}
                                    </div>

                                    <label className='mt-4'><b>Select Colour:</b></label><br></br>
                                    <Row>
                                        {data.color?.map((Item) => (
                                            <Col sm={2} className="p-1">
                                                <a href={'/Product?ProductId=' + Item.child_productID}><Image className='childimages' src={Item.child_product_Image}></Image><br></br><center className='childItem'>{Item.color_Name}</center></a>
                                            </Col>))}
                                    </Row>
                                    <div className='mt-4'><Button className='btnLarge' onClick={Addcart}><FontAwesomeIcon icon={faBasketShopping}></FontAwesomeIcon> Add to Bag</Button><Button className='btntransparent'><Image width={50} src={heart_img}></Image></Button></div>
                                    <hr></hr>
                                    <label className='mt-4'><FontAwesomeIcon size='1x' icon={faTruckFast}></FontAwesomeIcon><b>&nbsp; Delivery Details:</b></label> <div class="checkDelivery-box"><Button className='fa-check' >Check</Button><input type='text' placeholder='Enter pincode' className='form-control large-textboxsec'></input></div><br></br>
                                    <hr></hr>
                                    <label className='mt-4'><b>Meterial & Fit:</b></label><br></br>
                                    <ul><li>
                                        {data.color && data.color.length > 0 ? (<p> Color: {data.color[0].color_Name}</p>) : (
                                            <p> Color: N/A</p>
                                        )}

                                    </li><li>
                                            <p> Fabric:  {data.fabrictype_Name}</p></li><li>
                                            <p> Fit:  {data.fit_Name}</p></li><li>
                                            <p> Sleeve:  {data.sleeve_Length}</p></li><li>
                                            <p> Collar:  {data.collar_Name}</p></li><li>
                                            <p> Pattern:  {data.pattern_Name}</p></li></ul>
                                    <hr></hr>
                                    <label className='mt-4'><b>Product Description:</b> <button className="toggle-button transparentbtn" onClick={togglePanel}>{/* Arrow div which rotates based on the panel state */}<span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span></button></label> <br></br>
                                    <div className={`panel ${isOpen ? 'open' : ''}`}>
                                        <p>{data.description}</p>
                                    </div>
                                    <hr></hr>
                                    <label className='mt-4'><b>Delivery and Returns:</b> <button className="toggle-button transparentbtn" onClick={togglePanelreturn}>{/* Arrow div which rotates based on the panel state */}<span className={`arrow ${isOpens ? 'open' : ''}`}>▼</span></button></label> <br></br>
                                    <div className={`panels ${isOpens ? 'open' : ''}`}>
                                        {data.isreturnable ? (
                                            <p><ul><li>Note: This product can be retunable till 10 days from the day of delivery.</li></ul></p>
                                        ) : (
                                            <p><ul><li>This product cannot be returnable.</li></ul></p>
                                        )}

                                    </div>
                                    <hr></hr>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={3}>
                            <img src={data.display_image} className="childimages" alt={data.name} />
                        </Col>
                        <Col md={9}>
                            <Idea />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Related />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div key={refreshKey} className='cartsection'>
                <Cart />
            </div>
        </>

    )
}
export default ProductDetailCore;