import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/Login.css";
import Home from "./Components/Customer/Home";
import ProductDetails from "./Components/Customer/ProductDetails";
import Adminlogin from "./Components/Admin_Login";
import AdminDashboard from "./Components/Dashboard";
import ProductList from "./Components/ProductList";
import Category from './Components/Category';
import Subcategory from './Components/Subcategory';
import Forgotpassword from './Components/Forgotpassword';
import AddProduct from './Components/Addproduct';
import Colormanager from './Components/Colormanager';
import EditProduct from './Components/EditProduct';
import Dashboardcounts from './Components/Dashboardcounts';
import Adminprofile from './Components/Adminprofile';
import Comboproduct from './Components/Comboproduct';
import EditCombo from './Components/Editcombo';
import AddBanner from './Components/Addbanner';
import BannerList from './Components/Bannerlist';
import PrivateRoute from './Components/PrivateRoute';  // Import the PrivateRoute component
import AllProducts from './Components/Customer/Allproduct';
import PrivacyPolicy from './Components/Customer/privacypolicy';
import Termsandconditions from './Components/Customer/termsandconditions';
import Shipping from './Components/Customer/shipping';
import Cancellation from './Components/Customer/cancellation';




function App() {
     return (
        <> <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/AllProducts" element={<AllProducts />} />
                <Route path="/Product" element={<ProductDetails />} />
                <Route path="/AdminLogin" element={<Adminlogin />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/tandc" element={<Termsandconditions/>} />
                <Route path="/shippingPolicy" element={<Shipping/>} />
                <Route path="/ReturnandCancellation" element={<Cancellation/>} />
                <Route
                    path="/AdminDashboard/*"
                    element={<PrivateRoute>
                        <AdminDashboard />
                    </PrivateRoute>} />
                <Route
                    path="/ProductList"
                    element={<PrivateRoute>
                        <ProductList />
                    </PrivateRoute>} />
                <Route
                    path="/Category"
                    element={<PrivateRoute>
                        <Category />
                    </PrivateRoute>} />
                <Route
                    path="/Comboproduct"
                    element={<PrivateRoute>
                        <Comboproduct />
                    </PrivateRoute>} />
                <Route
                    path="/Subcategory"
                    element={<PrivateRoute>
                        <Subcategory />
                    </PrivateRoute>} />
                <Route
                    path="/bannerlist"
                    element={<PrivateRoute>
                        <BannerList />
                    </PrivateRoute>} />
                <Route
                    path="/Subcategory/:categoryId"
                    element={<PrivateRoute>
                        <Subcategory />
                    </PrivateRoute>} />
                <Route
                    path="/adminprofile"
                    element={<PrivateRoute>
                        <Adminprofile />
                    </PrivateRoute>} />
                <Route path="/forgotpassword" element={<Forgotpassword />} />
                <Route
                    path="/AddProduct"
                    element={<PrivateRoute>
                        <AddProduct />
                    </PrivateRoute>} />
                <Route
                    path="/Colormanager"
                    element={<PrivateRoute>
                        <Colormanager />
                    </PrivateRoute>} />
                <Route
                    path="/EditProduct/:id"
                    element={<PrivateRoute>
                        <EditProduct />
                    </PrivateRoute>} />
                <Route
                    path="/AdminDashboard/dashboardcounts"
                    element={<PrivateRoute>
                        <Dashboardcounts />
                    </PrivateRoute>} />
                <Route
                    path="/editcombo/:id"
                    element={<PrivateRoute>
                        <EditCombo />
                    </PrivateRoute>} />
                <Route
                    path="/addbanner"
                    element={<PrivateRoute>
                        <AddBanner />
                    </PrivateRoute>} />
               
            </Routes>
        </Router>
       
     </>
    );
}

export default App;
