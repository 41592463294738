import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Image, Carousel, Button, NavbarCollapse } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/productsList.css';


const ProducList = () => {
    const [data, setData] = useState([]);
    const [Catdata, setCataData] = useState([]);
    const [Colordata, setColorData] = useState([]);
    const [fabricdata, setfabricData] = useState([]);
    const [fitdata, setfitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [colorCategory, setColorCategory] = useState([]); // Initial state
    const [Fabric, setFabric] = useState([]); // Initial state
    const [Category, setCategory] = useState([]); // Initial state
    const location = useLocation(); // Provides the current URL object
    const params = new URLSearchParams(location.search);
    const queryCategory = params.get("Category_ID"); // Get the value for param1
    const querySubcategory = params.get("Subcategory_ID"); // Get the value for param2
    let color_ID = null;
    let category_ID = queryCategory;
    let subCategory_ID = querySubcategory;
    let fabricator_ID = null;
    let fit_ID = null;
    const [price, setPrice] = useState(0); // Initial price value
    const minPrice = 0;
    let maxPrice = 10000;


    function handleCatCheckClick(event) {
       
        const { id, checked } = event.target;

        setCategory((prevSelectedIds) => {
            if (checked) {
                // Add the checkbox ID if checked
                return [...prevSelectedIds, id];
                fetchProducts();
            } else {
                // Remove the checkbox ID if unchecked
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            }
        });
    };

    // Array of checkboxes with unique IDs and labels


    // Handle checkbox click to check only one and uncheck others
    const handlePriceChange = (e) => {
        setPrice(e.target.value); // Update price on slider change
        fetchProducts();
    };
    function handlefabricCheckboxClick(event) {
       
        const { id, checked } = event.target;

        setFabric((prevSelectedIds) => {
            if (checked) {
                // Add the checkbox ID if checked
                return [...prevSelectedIds, id];
                fetchProducts();
            } else {
                // Remove the checkbox ID if unchecked
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            }
        });
    };
    function handlefitTypeCheckboxClick() {

    };
    function handlecolorCheckboxClick(event) {
     
        const { id, checked } = event.target;

        setColorCategory((prevSelectedIds) => {
            if (checked) {
                // Add the checkbox ID if checked
                return [...prevSelectedIds, id];
                fetchProducts();
            } else {
                // Remove the checkbox ID if unchecked
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            }
        });
   }

    useEffect(() => {

        fetchCategories();
        fetchcolors();
        fetchfabric();
        fetchfitType();
        fetchProducts();

    }, [Fabric,Category,colorCategory,price]);

    const fetchCategories = async () => {
        fetch('https://api.rollerfashions.com/api/Home/Home_Category') // Replace with your API endpoint  
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((Catdata) => {
                setCataData(Catdata);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }; //

    const fetchcolors = async () => {
        fetch('https://api.rollerfashions.com/api/Home/Home_Color') // Replace with your API endpoint
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((Colordata) => {
                setColorData(Colordata);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    const fetchfabric = async () => {
        fetch('https://api.rollerfashions.com/api/Home/Home_Fabrictype') // Replace with your API endpoint
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((fabricdata) => {
                setfabricData(fabricdata);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    const fetchfitType = async () => {
        fetch('https://api.rollerfashions.com/api/Home/Home_fittype') // Replace with your API endpoint
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((fitdata) => {
                setfitData(fitdata);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    const fetchProducts = async () => {
       
        if (colorCategory.length > 0) {
            color_ID = colorCategory.join(", ");
        }
        if (Category.length > 0) {
            category_ID = Category.join(", ");
        }
        if (Fabric.length > 0) {
            fabricator_ID = Fabric.join(", ");
        }
        if (price > 0) {
            maxPrice = price;
        }
        let apiUrl = `https://api.rollerfashions.com/api/Home/Filter_Product?Category_ID=${category_ID}&Subcategory_ID=${subCategory_ID}&Fit_ID=${fit_ID}&Color_ID=${color_ID}&Maxprice=${maxPrice}&Minprice=${minPrice}&Fabricator_ID=${fabricator_ID}`;
        debugger;

        fetch(apiUrl) // Replace with your API endpoint
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });


    };

    // Empt

    if (loading) return <div> Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className='dvProductspge padding'>
            <Container fluid>
                <Row>
                    <Col md={3} className='AlignLeft'>
                        <h5>Categories</h5>
                        <ul>
                            {Catdata.map((CatItem) => (
                                <li>
                                    <input key={CatItem.id} id={CatItem.id} checked={CatItem.checked} type='checkbox' name='Category' value={CatItem.name} onChange={handleCatCheckClick} /><span>{CatItem.name}</span></li>
                            ))}</ul>
                        <hr></hr>
                        <h5>Price Range: ₹ {price}</h5>
                        <ul>
                            <li>
                                <div className="price-range-container">


                                    {/* Range input for selecting price */}
                                    <input
                                        type="range"
                                        id="priceRange"
                                        min={minPrice}
                                        max={maxPrice}
                                        value={price}
                                        onChange={handlePriceChange}
                                        className="price-slider"
                                    />

                                    {/* Progress bar to represent selected price */}
                                    <div className="progress-bar-container">
                                        <div
                                            className="progress-bar"
                                            style={{ width: `${(price / maxPrice) * 100}%` }} // Dynamically adjust width
                                        ></div>
                                    </div>

                                    {/* Display price range */}
                                    <div className="price-labels">
                                        <span>₹{minPrice}</span>
                                        <span>₹{maxPrice}</span>
                                    </div>
                                </div>
                            </li>
                        </ul><hr></hr>
                        <h5>Color</h5>
                        <ul>
                            {Colordata.map((CItem) => (
                                <li>
                                    <input key={CItem.id} id={CItem.id} checked={CItem.checked} type='checkbox' name='Color' value={CItem.id} onChange={handlecolorCheckboxClick} /><span>{CItem.name}</span></li>
                            ))}</ul><hr></hr>
                        <h5>Fabric</h5>
                        <ul>
                            {fabricdata.map((fItem) => (
                                <li>
                                    <input key={fItem.id} id={fItem.id} checked={fItem.checked} type='checkbox' name='Fabric' value={fItem.id} onChange={handlefabricCheckboxClick} /><span>{fItem.fabricname}</span></li>
                            ))}</ul><hr></hr>
                        <h5>FitType</h5>
                        <ul>
                            {fitdata.map((fitItem) => (
                                <li>
                                    <input key={fitItem.id} id={fitItem.id} checked={fitItem.checked} type='checkbox' name='Fit' value={fitItem.id} onChange={handlefitTypeCheckboxClick} /><span>{fitItem.fitname}</span></li>
                            ))}</ul>
                    </Col>
                    <Col md={9}>
                        <h2 className='top-categories-heading mt-2'><span className="thin"></span></h2>

                        <Row>
                            {data.map((product) => (
                                <Col md={3} key={product.id}>
                                    <div key={product.id} className="product-item">
                                        <a href={'/Product?ProductId='+product.id}>
                                        <Carousel fade interval={null} className="hover-carousel" >
                                            <Carousel.Item>
                                                <img src={product.display_image} className="d-block w-100" alt={product.name} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img src={product.sample_image1} className="d-block w-100" alt={product.name} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img src={product.sample_image2} className="d-block w-100" alt={product.name} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img src={product.sample_image3} className="d-block w-100" alt={product.name} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img src={product.sample_image4} className="d-block w-100" alt={product.name} />
                                            </Carousel.Item>
                                        </Carousel>
                                        </a>
                                        <label title="Add to wishlist" className="WishListicons"></label>
                                        {product.color.map((item, index) => (
                                            <Image title={item.color_Name} src={item.color_Image} className="circle"></Image>))}
                                        <h5>{product.name}</h5>
                                        <p>&#8377;&nbsp;{product.selling_price} <strike>{product.price}</strike><span className='disc'>{product.discount}%</span></p>
                                        <div id={'dv' + product.id} className='sizechart_mini'>
                                            <Container fluid className='sizemini'>
                                                <Row>
                                                    <Col sm={2}>
                                                        <a href='#'>S</a>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <a href='#'>M</a>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <a href='#'>L</a>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <a href='#'>XL</a>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <a href='#'>XXL</a>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </Col>

                            ))}
                        </Row>

                    </Col>

                </Row>
   </Container> </div>

    );
}

export default ProducList;
