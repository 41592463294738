// src/TermsOfService.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image,Button } from 'react-bootstrap';
import '../../assets/css/cart.css';
import $ from 'jquery';
import iconclose from '../../assets/images/hugeicons_cancel-circle.png';

const Cart = () => {
  const [CartCount, setCartCount] = useState(0);
  const [CartId, SetCartId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Cdata, setCData] = useState([]); // Initial state
  const [CartAmount, SetCartAmount]=useState('');
  const [data, Data] = useState([]); // Initial state

  
  // Function to handle the "+" button
  function handleIncrease(event){
    debugger;
    let id= event.target.id;
    let item= $("#txt"+id).val();
    item = parseInt(item, 10);
    $("#txt"+id).val(item + 1); 
  };

  // Function to handle the "-" button
  const handleDecrease = (event) => {
    
    let id= event.target.id;
    let item= $("#txt"+id).val();
    item = parseInt(item, 10);
    $("#txt"+id).val(item - 1); 
  };
  const CloseCart = (event) => {
    
    $(".dvCart").hide();
    
  };

  // Function to handle changes in the input box directly
  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1) {
      $(e).val(value);
    }
  };
  useEffect(() => {
    fetchcart();
    fetchCartCount();
    

  }, []);
  const fetchCartCount = async () => {
    let SessionCartid = sessionStorage.getItem('sessioncart_Id');
    SetCartId(SessionCartid);
    if (SessionCartid != null) {
      fetch(`https://api.rollerfashions.com/api/Cart/Cart_count?Cart_ID=${SessionCartid}`) // Replace with your API endpoint
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })  
        .then((data) => {
          debugger;
          Data(data);
          setLoading(false);debugger;

          const totalSum = data.reduce((acc, CartItem) => acc + CartItem.product_Sellingprice, 0);
          SetCartAmount(totalSum);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  };


  const fetchcart = async () => {
    let SessionCartid = sessionStorage.getItem('sessioncart_Id');
    SetCartId(SessionCartid);
    if (SessionCartid != null) {
      fetch(`https://api.rollerfashions.com/api/Cart/List_cart?Cart_ID=${SessionCartid}`) // Replace with your API endpoint
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          debugger;
          setCData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }

  }; // 
  return (
<div className='dvCart'>
  <div className='dvCartInnr'>
    <Container>
      <Row>
        <Col md={9}>
          <label>BAG ({data.cartcount} items)</label>
        </Col>
<Col md={2}>
<Button className='btnCartClose' onClick={CloseCart}><Image src={iconclose}></Image></Button></Col>

      </Row>
      <br></br>
      {Cdata != null}
      {Cdata.map((CartItem) => (
        <>
        <br></br>
        <Row>
          <Col md={5}>
            <Image className="CartImage" src={CartItem.image}></Image>
          </Col>
          <Col md={7}>
            <h6 className='h6'>{CartItem.name}</h6>
            <label>SIZE : {CartItem.size}</label><br></br>
            <label>COLOUR : {CartItem.product_Colorname}</label>
            <table className='tablecart'>
              <tr>
                <td><div style={{ display: 'flex', alignItems: 'center' }}>
                  <button id={CartItem.id} className='btnsmall' onClick={handleDecrease}>-</button>

                  <input
                    id={'txt'+CartItem.id}
                    type="number"
                    value={CartItem.quantity}
                    readOnly='true'
                    onChange={handleChange}
                    className='txtqty'
                    min="1"
                  />
                  <button id={CartItem.id} className='btnsmalln' onClick={handleIncrease}>+</button>
                </div></td>
                <td><label>&#8377; {CartItem.product_Sellingprice}</label></td>
              </tr>
            </table>
            <br></br>
          </Col>
        </Row>
        </>
      ))}
    </Container>
    </div>
    <button className='btnLargeChk'>CHECK OUT - &#8377; {CartAmount}</button></div>
  )
};

export default Cart;
